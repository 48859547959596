import apiClient from "utility/apiClient";
import { fixMeasurement } from "./utility";

export function getMisure(rilievoId) {
  return apiClient({ cache: false })({
    action: "get_misure",
    data: {
      id_rilievo: rilievoId,
    },
  }).then(measurements => measurements.map(fixMeasurement));
}

export function createMisura(rilievoId, type, props) {
  console.log(rilievoId, type, props);

  return apiClient({ cache: false })({
    action: "set_misura",
    data: {
      id_rilievo: rilievoId,
      tipo: type,
      misura: JSON.stringify({
        scale: {},
        position: {},
        points: {},
        clip: false,
        ...props,
      }),
    },
  });
}

export function removeMisura(id) {
  return apiClient({ cache: false })({
    action: "remove_misura",
    data: {
      id_misura: id,
    },
  });
}

export function setPermissions(id, permission) {
  return apiClient({
    useCache: false,
  })({
    action: "permission_misura",
    data: {
      id_misura: id,
      permesso: permission,
    },
  });
}

export function updateMisura({
  id,
  tipo,
  titolo,
  points,
  position,
  width,
  rotation,
  scale,
  clip,
  showLabels,
}) {
  try {
    const data = JSON.stringify({
      points,
      width,
      scale,
      rotation,
      position,
      clip,
    }).replace(/"/g, "&quot;");

    return apiClient({ cache: false })({
      action: "update_misura",
      data: {
        id_misura: id,
        misura: data,
        titolo,
        tipo,
        visibile: showLabels ? "1" : "0",
      },
    });
  } catch (err) {
    console.log(err);
  }
}
