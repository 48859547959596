import React, { useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { transparentize } from "polished";

import apiClient from "utility/apiClient";
import * as qs from "query-string";

import logo from "../logo.svg";

import { setUser, userSelector } from "store/user";

const Field = React.forwardRef((props, ref) => {
  const { label, value, type, name, onChange } = props;
  return (
    <Field.Container>
      <Field.Label for={name}>{label}</Field.Label>
      <Field.Input
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        ref={ref}
      />
    </Field.Container>
  );
});

Field.Container = styled.div`
  margin-bottom: 1rem;
`;

Field.Label = styled.label`
  font-size: 10px;
  display: block;
  text-transform: uppercase;
  color: #eee;
  margin-bottom: 10px;
`;

Field.Input = styled.input`
  font-size: 1rem;
  height: 3rem;
  background-color: #000;
  border-radius: 3px;

  width: 100%;
  padding: 0 1rem;

  box-shadow: 0 0 0 5px ${props => transparentize(1, props.theme.high)};
  transition: all 0.3s ease;

  color: white;

  -webkit-appearance: none;

  &:focus {
    box-shadow: 0 0 0 2px ${props => props.theme.high};
  }

  border: 1px solid #444;
  outline: none;
`;

function LoginForm(props) {
  const [values, setValues] = useState({
    email: null,
    pw: null,
  });

  const emailInput = React.useRef();

  const [error, setError] = useState(null);

  async function handleSubmit(e) {
    const { setUser } = props;

    e.preventDefault();

    try {
      const data = await apiClient({})({ data: values, action: "login" });

      setUser({ ...data, clientId: process.env.REACT_APP_CLIENT_ID });
    } catch (err) {
      setError(err.message);
    }
  }

  function handleChange(name, value) {
    setValues({
      ...values,
      [name]: value,
    });

    setError(null);
  }

  React.useEffect(() => {
    emailInput.current.focus();
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Field
        label="Email"
        name="email"
        value={values["email"]}
        onChange={e => handleChange("email", e.target.value)}
        ref={emailInput}
      />
      <Field
        label="Password"
        name="pw"
        value={values["pw"]}
        type="password"
        onChange={e => handleChange("pw", e.target.value)}
      />

      {error && <ErrorMessage>{error}</ErrorMessage>}

      <SubmitBtn type="submit"> Login </SubmitBtn>
    </form>
  );
}

function MagicForm(props) {
  const [values, setValues] = useState({
    email: null,
    pw: null,
  });

  const [error, setError] = useState(null);

  function handleChange(name, value) {
    setValues({
      ...values,
      [name]: value,
    });

    setError(null);
  }

  async function handleSubmit(e) {
    const { setUser } = props;

    e.preventDefault();

    try {
      const data = await apiClient({})({
        data: values,
        action: "crea_onetime_token",
      });

      setUser({ ...data, clientId: process.env.REACT_APP_CLIENT_ID });
    } catch (err) {
      setError(err.message);
    }
  }

  const emailInput = React.useRef();

  React.useEffect(() => {
    emailInput.current.focus();
  }, []);

  return (
    <>
      <p>
        Inserisci l'email con la quale sei stato invitato e ti invieremo un link
        di accesso.
      </p>
      <form onSubmit={handleSubmit}>
        <Field
          label="Email"
          name="email"
          value={values["email"]}
          onChange={e => handleChange("email", e.target.value)}
          ref={emailInput}
        />

        {error && <ErrorMessage>{error}</ErrorMessage>}

        <SubmitBtn type="submit">Invia Link Per Accedere</SubmitBtn>
      </form>
    </>
  );
}

function Login(props) {
  if (props.userLogged) {
    const { to } = qs.parse(window.location.search);

    return <Redirect to={to ? window.decodeURIComponent(to) : "/"} />;
  }

  const [magic, setMagic] = React.useState(false);

  return (
    <Container>
      <Wrapper>
        <Logo src={logo} />
        {magic ? <MagicForm /> : <LoginForm {...props} />}

        {/* <Lol onClick={() => setMagic(!magic)}>
          {magic ? "Accedi con username e password" : "Accedi con link magico"}
        </Lol> */}
      </Wrapper>
    </Container>
  );
}

const mapStateToProps = state => ({
  userLogged: userSelector(state),
});

export default connect(
  mapStateToProps,
  { setUser }
)(Login);

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;

  p {
    line-height: 1.6;
    margin-bottom: 2rem;
  }
`;

const Wrapper = styled.div`
  flex: 400px 0 0;
  margin: 5rem;
`;

const SubmitBtn = styled.button`
  font-size: 12px;
  color: white;
  background-color: ${props => props.theme.high};
  border: none;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: 3px;
  margin-top: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  box-shadow: rgba(14, 21, 47, 0.3) 0px 8px 30px;

  border-radius: 8px;

  letter-spacing: 1px;

  &:hover {
    background-color: #068dc7;
    cursor: pointer;
  }
`;

const Logo = styled.img`
  width: 32px;
  margin-bottom: 8rem;
`;

const ErrorMessage = styled.div`
  padding: 0.5rem;
  font-size: 11px;
  background-color: ${props => props.theme.red};
  color: white;

  margin-top: 0.5rem;
`;

const Lol = styled.div`
  font-size: 14px;

  margin-top: 1rem;
  display: block;

  text-decoration: underline !important;
  cursor: pointer;
`;
