import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ThemeProvider } from "styled-components";
import { ConnectedRouter } from "connected-react-router";

import "rc-tooltip/assets/bootstrap.css";

import Login from "pages/Login";

import Root from "components/Root";
import LoggedRoute from "components/LoggedRoute";
import PageLoading from "components/PageLoading";

import { store, persistor, history } from "./store";

const Survey = React.lazy(() => import("modules/Survey"));
const PhotoInspection = React.lazy(() => import("modules/PhotoInspection"));

const UnsupportedBrowser = React.lazy(() =>
  import("components/UnsupportedBrowser")
);
const MapView = React.lazy(() => import("components/MapView"));
const Converter = React.lazy(() => import("pages/Converter"));
const MagicLogin = React.lazy(() => import("pages/magic-login"));

function App(props) {
  const unsupported = typeof WebGL2RenderingContext === "undefined";

  const envTheme =
    process.env.REACT_APP_THEME && JSON.parse(process.env.REACT_APP_THEME);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <ThemeProvider
            theme={{
              high: "#2F9EED",
              green: "#2ecc71",
              red: "#e74c3c",
              semitransparentBlack: "rgba(25, 24, 27, 0.8)",
              ...envTheme,
            }}
          >
            <React.Suspense fallback={<PageLoading />}>
              {unsupported ? (
                <UnsupportedBrowser />
              ) : (
                <Switch>
                  <Route path="/magic-login" exact component={MagicLogin} />
                  <Route path="/login" exact component={Login} />
                  <LoggedRoute
                    path="/"
                    component={() => {
                      return (
                        <Root>
                          <Switch>
                            <Route
                              path="/projects/:projectId/:rilievoId/:markerId/photoinspection"
                              exact
                              component={PhotoInspection}
                            />
                            <Route
                              path="/projects/:projectId/:rilievoId/:itemType?/:itemId?"
                              exact
                              component={Survey}
                            />
                            <Route
                              path="/projects/:projectId"
                              exact
                              component={MapView}
                            />
                            <Route
                              path="/converter"
                              exact
                              component={Converter}
                            />
                            <Route
                              path="*"
                              render={() => <Redirect to="/projects/1" />}
                            />
                          </Switch>
                        </Root>
                      );
                    }}
                  />
                </Switch>
              )}
            </React.Suspense>
          </ThemeProvider>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
