import { createSelector } from "reselect";
import { createActions, handleActions, combineActions } from "redux-actions";
import uniqBy from "lodash.uniqby";

import apiClient from "utility/apiClient";

/* -- actions -- */
export const fetchRilievi = (projectId = 1) => async dispatch => {
  dispatch(fetchRilieviRequest(projectId));

  try {
    const data = await apiClient({ cache: false })({
      action: "get_rilievi",
      data: {
        id_progetto: projectId,
      },
    });

    dispatch(fetchRilieviSucceeded(data));
  } catch (err) {
    dispatch(fetchRilieviFailed(err));
  }
};

export const fetchAllRilievi = () => async dispatch => {
  dispatch(fetchRilieviRequest());

  try {
    const data = await apiClient({ cache: false })({
      action: "get_rilievi",
    });

    dispatch(fetchRilieviSucceeded(data));
  } catch (err) {
    dispatch(fetchRilieviFailed(err));
  }
};

export const {
  fetchRilieviRequest,
  fetchRilieviSucceeded,
  fetchRilieviFailed,
} = createActions({
  FETCH_RILIEVI_REQUEST: projectId => ({ projectId }),
  FETCH_RILIEVI_SUCCEEDED: rilievi => {
    return {
      rilievi: rilievi.map(rilievo => {
        return {
          ...rilievo,
          lat: parseFloat(rilievo.lat),
          lng: parseFloat(rilievo.lng),
        };
      }),
    };
  },
  FETCH_RILIEVI_FAILED: err => ({ err }),
});

export const fetchRilievo = (rilievoId = 1) => async dispatch => {
  dispatch(fetchRilievoRequest(rilievoId));

  try {
    const data = await apiClient({ cache: false })({
      action: "get_rilievo",
      data: {
        id_rilievo: rilievoId,
      },
    });

    dispatch(fetchRilievoSucceeded(data));
  } catch (err) {
    dispatch(fetchRilievoFailed(err));
  }
};

export const {
  fetchRilievoRequest,
  fetchRilievoSucceeded,
  fetchRilievoFailed,
  updateRilievo,
} = createActions({
  FETCH_RILIEVO_REQUEST: projectId => ({ projectId }),
  FETCH_RILIEVO_SUCCEEDED: rilievo => {
    return {
      ...rilievo,
      lat: parseFloat(rilievo.lat),
      lng: parseFloat(rilievo.lng),
    };
  },
  FETCH_RILIEVO_FAILED: err => ({ err }),
  UPDATE_RILIEVO: undefined,
});

/* -- reducers --  */
export const reducer = handleActions(
  {
    [combineActions(fetchRilievi, fetchRilievo)]: state => {
      return {
        ...state,
        loading: true,
      };
    },
    [fetchRilievoSucceeded]: (state, { payload }) => {
      const indexOfRilievo = state.data.find(
        cachedRilievo => cachedRilievo.id === payload.id
      );

      if (typeof indexOfRilievo === "undefined") {
        return {
          ...state,
          data: [...state.data, payload],
        };
      } else {
        return {
          ...state,
          data: state.data.map(cachedRilievo =>
            cachedRilievo.id === payload.id ? payload : cachedRilievo
          ),
        };
      }
    },
    [fetchRilieviSucceeded]: (state, { payload: { rilievi } }) => {
      const data = uniqBy([...state.data, ...rilievi], "id");

      return {
        ...state,
        loading: false,
        data,
      };
    },
    [updateRilievo]: (state, { payload: { rilievoId, name, value } }) => {
      return {
        ...state,
        data: state.data.map(rilievo =>
          rilievo.id === rilievoId
            ? {
                ...rilievo,
                [name]: value,
              }
            : rilievo
        ),
      };
    },
  },
  {
    data: [],
    loading: false,
  }
);

/* -- selectors -- */
export const rilieviSelector = state => state.rilievi.data;

export const rilievoSelectorByProjectId = projectId =>
  createSelector(
    rilieviSelector,
    rilievi => rilievi.filter(rilievo => projectId === rilievo.id_progetto)
  );

export const rilievoSelectorById = rilievoId =>
  createSelector(
    rilieviSelector,
    rilievi => rilievi.find(rilievo => rilievoId === rilievo.id)
  );

export const rilieviSortedSelector = state =>
  rilieviSelector(state).sort((x, y) => (x.location > y.location ? 1 : -1));
